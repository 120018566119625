import { Suspense, lazy } from 'react';
import { Navigate } from 'react-router-dom';
import { RouteObject } from 'react-router';

import SidebarLayout from 'src/layouts/SidebarLayout';
import BaseLayout from 'src/layouts/BaseLayout';

import SuspenseLoader from 'src/components/SuspenseLoader';
// import { Dashboard } from '@mui/icons-material';

const Loader = (Component) => (props) =>
  (
    <Suspense fallback={<SuspenseLoader />}>
      <Component {...props} />
    </Suspense>
  );


const Dashboard = Loader(lazy(() => import('src/content/dashboard')))
const Setting = Loader(lazy(() => import('src/content/setting')))
const LG = Loader(lazy(() => import('src/content/lg')))
const CHROMECAST = Loader(lazy(() => import('src/content/chromecast')))
const MS = Loader(lazy(() => import('src/content/ms')))
const Donation = Loader(lazy(() => import('src/content/donation')))
const SignIn = Loader(lazy(() => import('src/content/login')))
console.log(CHROMECAST)
// Applications

// Status

const Status404 = Loader(
  lazy(() => import('src/content/pages/Status/Status404'))
);
const Status500 = Loader(
  lazy(() => import('src/content/pages/Status/Status500'))
);
const StatusComingSoon = Loader(
  lazy(() => import('src/content/pages/Status/ComingSoon'))
);
const StatusMaintenance = Loader(
  lazy(() => import('src/content/pages/Status/Maintenance'))
);

const routes: RouteObject[] = [
  {
    path: '',
    element: <BaseLayout />,
    children: [
      {
        path: '/',
        element: <Navigate to="dashboard" replace />
      }
    ]
  },
  {
    path: 'dashboard',
    element: <SidebarLayout />,
    children: [
      {
        path: '',
        element: <Dashboard />
      }
    ]
  },
  {
    path: 'setting',
    element: <SidebarLayout />,
    children: [
      {
        path: '',
        element: <Setting />
      }
    ]
  },
  {
    path: 'lg',
    element: <SidebarLayout />,
    children: [
      {
        path: '',
        element: <LG />
      }
    ]
  },
  {
    path: 'chromecast',
    element: <SidebarLayout />,
    children: [
      {
        path: '',
        element: <CHROMECAST />
      }
    ]
  },
  {
    path: 'ms',
    element: <SidebarLayout />,
    children: [
      {
        path: '',
        element: <MS />
      }
    ]
  },
  {
    path: 'donation',
    element: <SidebarLayout />,
    children: [
      {
        path: '',
        element: <Donation />
      }
    ]
  },
  {
    path: 'login',
    element: <BaseLayout />,
    children: [
      {
        path: '',
        element: <SignIn />
      }
    ]
  },
];

export default routes;
